import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { base_url } from "../../Global";

const AppManager = () => {
  const [iosVersion, setIosVersion] = useState("");
  const [androidVersion, setAndroidVersion] = useState("");
  const [isEditIosVersion, setIsEditIosVersion] = useState(false);
  const [isEditAndroidVersion, setIsEditAndroidVersion] = useState(false);

  const getAppInfo = async () => {
    try {
      const response = await fetch(base_url + "/app/getInfo?appID=legacyApp");
      const resp = await response.json();
      console.log("resp", resp);
      const data = resp?.appsetting;
      console.log("data", data);
      setIosVersion(data[0]?.iosVersion ?? "");
      setAndroidVersion(data[0]?.androidVersion ?? "");
    } catch (error) {}
  };
  useEffect(() => {
    getAppInfo();
  }, []);

  const handleIosVersionChange = (event) => {
    setIosVersion(event.target.value);
  };
  const handleAndroidVersionChange = (event) => {
    setAndroidVersion(event.target.value);
  };
  const handleIosEdit = () => {
    setIsEditIosVersion(!isEditIosVersion);
  };
  const handleIAndroidEdit = () => {
    setIsEditAndroidVersion(!isEditAndroidVersion);
  };

  const handleComplete = async () => {
    let temp = {
      appID: "legacyApp",
    };
    if (isEditIosVersion) {
      temp.iosVersion = iosVersion;
    }
    if (isEditAndroidVersion) {
      temp.androidVersion = androidVersion;
    }
    if (temp?.androidVersion || temp.iosVersion) {
      const url = base_url + "/app/updateInfo";
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(temp),
      });
      console.log("response", response);
      if (response.status === 200) {
        alert("Data Updated");
      } else {
        alert("Something went wrong! Try Again");
      }
    }
  };
  return (
    <section class="flex-1 rounded-xl w-full ml-[19rem] mt-[8rem] bg-gray-200 flex flex-col items-start justify-start pt-[2.125rem] px-[2.125rem] pb-[24.625rem] box-border gap-[0.875rem_0rem] max-w-full text-left text-[1.125rem] text-white font-gilroy mq925:pt-[1.375rem] mq925:pb-[16rem] mq925:box-border mq925:max-w-full mq450:pt-[1.25rem] mq450:pb-[10.375rem] mq450:box-border">
      <ToastContainer className="text-[1rem]" />
      <div class="flex-1 flex flex-col w-full items-end justify-start  min-w-[27.125rem] max-w-full text-[1.375rem] mq700:min-w-full">
        <div class="flex  items-start  w-full justify-between  max-w-full text-[1.125rem] mq700:flex-wrap">
          <h1 class="m-0  relative text-inherit leading-[2.375rem] text-[40px] capitalize font-semibold font-inherit inline-block shrink-0 z-[1]  ">
            App Manager
          </h1>{" "}
        </div>
      </div>
      <div>
        <div class="self-stretch flex flex-row mt-7 flex-wrap items-start justify-start gap-[0rem_1.5rem] max-w-full">
          <div class="flex-1 flex flex-col items-start justify-start gap-[1.5rem_0rem] min-w-[20.063rem] max-w-full">
            <div class="h-[0.75rem] w-full leading-[1.125rem] capitalize flex items-center justify-between shrink-0 z-[1]">
              <h6>IOS version</h6>
              <button
                onClick={handleIosEdit}
                class="cursor-pointer [border:none] p-[0.25rem] bg-white rounded-3xs flex flex-row items-end justify-start gap-[0rem_0.5rem] z-[1] hover:bg-gainsboro-100"
              >
                Edit
              </button>
            </div>
            <input
              type="text"
              placeholder="IOS version"
              disabled={!isEditIosVersion}
              value={iosVersion}
              onChange={handleIosVersionChange}
              className="w-full [border:none] [outline:none] flex justify-between placeholder:text-black  self-stretch h-[3.375rem] px-3 rounded-3xs flex flex-row items-start justify-start py-[1rem] px-[1.125rem] box-border font-gilroy font-light text-[1.125rem] text-gray-400 min-w-[15.625rem] z-[1]"
            />
          </div>
        </div>
        <div class="self-stretch flex flex-row mt-7 flex-wrap items-start justify-start gap-[0rem_1.5rem] max-w-full">
          <div class="flex-1 flex flex-col items-start justify-start gap-[1.5rem_0rem] min-w-[20.063rem] max-w-full">
            <div class="h-[0.75rem] w-full leading-[1.125rem] capitalize flex items-center justify-between shrink-0 z-[1]">
              <h6>Android version</h6>
              <button
                onClick={handleIAndroidEdit}
                class="cursor-pointer [border:none] p-[0.25rem] bg-white rounded-3xs flex flex-row items-end justify-start gap-[0rem_0.5rem] z-[1] hover:bg-gainsboro-100"
              >
                Edit
              </button>
            </div>
            <input
              type="text"
              placeholder="Android version"
              disabled={!isEditAndroidVersion}
              value={androidVersion}
              onChange={handleAndroidVersionChange}
              className="w-full [border:none] [outline:none] flex justify-between placeholder:text-black  self-stretch h-[3.375rem] px-3 rounded-3xs flex flex-row items-start justify-start py-[1rem] px-[1.125rem] box-border font-gilroy font-light text-[1.125rem] text-gray-400 min-w-[15.625rem] z-[1]"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-end">
        <button
          onClick={handleComplete}
          class="cursor-pointer [border:none] p-[0.5rem] bg-white rounded-3xs flex flex-row items-end justify-start gap-[0rem_0.5rem] z-[1] hover:bg-gainsboro-100"
        >
          Update
        </button>
      </div>
    </section>
  );
};

export default AppManager;
