import React, { useState, useEffect, useRef } from "react";
import vector12 from "../../assets/vector-12.svg";
import group51 from "../../assets/group-5-1.svg";
import Select from "react-select";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
// import MultiSelect from "./MultiSelect";
import { MultiSelect } from "react-multi-select-component";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import UsersMultiSelect from "./UsersMultiSelect";
import { base_url } from "../../Global";
import NotificationsTable from "./NotificationsTable";
const animatedComponents = makeAnimated();

const Notifications = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const newAllUsers = allUsers
  const [notificationText, setNotificationText] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationsList, setNotificationsList] = useState([]);

  const [selectFromMS, setselectFromMS] = useState([]);

  const notify = () => toast.success("Notification sent successfully!!!");
  const notif = () => toast.error("Error ");

  const fetchUserNotification = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(base_url + "/auth/getNotification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: accessToken,

          // Add any other headers required by your API
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const gettedNotifications = await response.json(); // Parse JSON response
      console.log("data fetchUserNotification", gettedNotifications?.data);
      const newList = [
        ...gettedNotifications?.data?.notifications50Plus,
        ...gettedNotifications?.data?.notifications50Less,
      ];

      setNotificationsList(newList);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(base_url + "/auth/allProfile");
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json(); // Parse JSON response
        // Extracting user name and user id
        const usersWithRequiredFields = data?.users
          ?.filter((user) => !!user.username?.trim())
          ?.map((user) => ({
            value: user._id,
            label: user.username,
          }));
        // console.log("usersWithRequiredFields", usersWithRequiredFields);

        // setOptions(usersWithRequiredFields);

        setUsers(usersWithRequiredFields); // Set users state with the extracted fields
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUserNotification();
    fetchUsers();
  }, []);

  // calll notification api

  const handleNotificationTitle = (event) => {
    const capitalizedValue =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setNotificationTitle(capitalizedValue);
  };

  const handleNotificationTextChange = (event) => {
    const capitalizedValue =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setNotificationText(capitalizedValue);
  };

  const handleNotificationSubmit = (event) => {
    event.preventDefault();
    const userIdsTemp = selectFromMS.map((item) => item.value);
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      throw new Error("Access token not found in local storage");
    }
    if (!notificationTitle || !notificationText) {
      toast.error("notification title or notification text is empty");
      handleClose();
      return;
    }
    // Perform API call here
    fetch(base_url + "/auth/send-notification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: accessToken,

        // Add any other headers required by your API
      },
      body: JSON.stringify({
        notificationType: notificationTitle,
        userIds: userIdsTemp,
        notificationText: notificationText,
      }),
    })
      .then((response) => {
        // Handle response from API
        if (response.ok) {
          // Notification successfully sent
          toast.success("Notification sent successfully");
          handleClose();
          notify();
          // Clear input fields
          setNotificationTitle(""); // Assuming setSelectedOption is a state setter
          setNotificationText("");
          setselectFromMS([]); // Assuming setNotificationText is a state setter
          fetchUserNotification();
        } else {
          notif();
          // Notification failed
          console.error("Failed to send notification");
        }
        return response.json(); // Parse response body as JSON
      })
      .then((data) => {
        console.log("API Response:", data); // Log the parsed JSON response
        // window.location.reload(false);
      })
      .catch((error) => {
        // Handle error
        handleClose();
        notif();
        console.error("Error:", error);
      });
  };

  return (
    <section class="flex-1 rounded-xl w-full ml-[19rem] mt-[8rem] bg-gray-200 flex flex-col items-start justify-start pt-[2.125rem] px-[2.125rem] pb-[24.625rem] box-border gap-[0.875rem_0rem] max-w-full text-left text-[1.125rem] text-white font-gilroy mq925:pt-[1.375rem] mq925:pb-[16rem] mq925:box-border mq925:max-w-full mq450:pt-[1.25rem] mq450:pb-[10.375rem] mq450:box-border">
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Do you really want to send a notification?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            No
          </Button>
          <Button onClick={handleNotificationSubmit} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer className="text-[1rem]" />
      <div class="flex-1 flex flex-col w-full items-end justify-start  min-w-[27.125rem] max-w-full text-[1.375rem] mq700:min-w-full">
        <div class="flex  items-start  w-full justify-between  max-w-full text-[1.125rem] mq700:flex-wrap">
          <h1 class="m-0  relative text-inherit leading-[2.375rem] text-[40px] capitalize font-semibold font-inherit inline-block shrink-0 z-[1]  ">
            Notification
          </h1>{" "}
        </div>
      </div>

      <div class="self-stretch flex flex-row mt-7 flex-wrap items-start justify-start gap-[0rem_1.5rem] max-w-full">
        <div class="flex-1 flex flex-col items-start justify-start gap-[1.5rem_0rem] min-w-[20.063rem] max-w-full">
          <div class="self-stretch flex flex-col items-start justify-start gap-[0.875rem_0rem]">
            <div class="h-[0.75rem] relative leading-[1.125rem] capitalize flex items-center shrink-0 z-[1]">
              Notification Title
            </div>
            <input
              type="text"
              placeholder="Notification Title"
              value={notificationTitle}
              onChange={handleNotificationTitle}
              className="w-full [border:none] [outline:none] flex justify-between placeholder:text-black  self-stretch h-[2.60rem] px-3 rounded-3xs flex flex-row items-start justify-start py-[1rem] px-[1.125rem] box-border font-gilroy font-light text-[1.125rem] text-gray-400 min-w-[15.625rem] z-[1]"
            />
          </div>
        </div>
        <div class="w-full flex-1 flex flex-col items-start justify-start gap-[1.5rem_0rem] min-w-[20.063rem] max-w-[30rem] xl:max-w-[40rem]">
          <div class="w-full self-stretch flex flex-col items-start justify-start gap-[0.875rem_0rem]">
            <div class=" w-full h-[0.75rem] relative leading-[1.125rem] capitalize flex items-center shrink-0 z-[1]">
              Select User's
            </div>
            <div class="w-full">
              <MultiSelect
                className="text-black h-[3.375rem] self-stretch"
                valueRenderer={(selected, _options) => {
                  return selected.length
                    ? selected.map(({ label }) => label + " , ")
                    : "No Items Selected";
                }}
                options={users}
                value={selectFromMS}
                onChange={setselectFromMS}
                labelledBy="Search and select users..."
              />
              {/* <UsersMultiSelect users={users} handleSelectedUsers={handleSelectedUsers}/> */}
            </div>
          </div>
        </div>
      </div>
      <div class="self-stretch flex flex-col mt-4 items-start justify-start gap-[0.875rem_0rem]">
        <div class="h-[0.75rem] relative leading-[1.125rem] capitalize flex items-center shrink-0 z-[1]">
          Notification Text
        </div>
        <textarea
          class="w-full [border:none] [outline:none] z-0  self-stretch h-[12.375rem] rounded-3xs flex flex-row items-start justify-start py-[1.313rem] px-[1.125rem] box-border font-gilroy font-light text-[1.125rem] placeholder:text-black text-gray-400 min-w-[15.625rem] z-[1]"
          placeholder="Write Somthing.."
          value={notificationText}
          onChange={handleNotificationTextChange}
          type="text"
        />
      </div>
      <button
        onClick={handleOpen}
        class="cursor-pointer [border:none] mt-4 py-[1.063rem] pr-[2.563rem] pl-[2.625rem] bg-white rounded-3xs flex flex-row items-start justify-start z-[1] hover:bg-gainsboro-100"
      >
        <div class="h-[2.875rem] w-[8.813rem] relative rounded-3xs bg-white hidden"></div>
        <div class="relative text-[1.125rem] leading-[0.75rem] capitalize font-gilroy text-gray-200 text-left z-[2]">
          submit
        </div>
      </button>
      <NotificationsTable notifications={notificationsList} />
    </section>
  );
};

export default Notifications;
